import { createSlice } from '@reduxjs/toolkit';
import IPagination from 'interfaces/IPagination';
import IProduct from 'models/Product';
import IProductDetails from 'models/ProductDetails';

import reducers from './reducers';

export interface IProductData {
  getAll: {
    config: {
      emptyMessage: string;
      errorMessage: string;
      isLoading: boolean;
      pagination: IPagination;
    };
    list: IProduct[];
    totalProducts: number;
  };
  getDetails: {
    config: {
      isLoading: boolean;
    };
    product: IProductDetails | null;
  };
  getProductToPdf: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IProductData = {
  getAll: {
    config: {
      isLoading: false,
      emptyMessage: 'Nenhum produto encontrado',
      errorMessage: '',
      pagination: {
        page: 1,
        totalPages: 1,
      },
    },
    list: [],
    totalProducts: 0,
  },
  getDetails: {
    config: {
      isLoading: false,
    },
    product: null,
  },
  getProductToPdf: {
    config: {
      isLoading: false,
    },
  },
};

const productSlice = createSlice({
  name: '@product',
  initialState,
  reducers,
});

export const productActions = productSlice.actions;
export const productReducers = productSlice.reducer;
