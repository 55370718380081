import { Draft, PayloadAction } from '@reduxjs/toolkit';

import IGetProductDetailsResponse from 'services/httpClient/responses/product/IGetProductDetailsResponse';

import { IProductData } from '../../index';

interface IGetDetailsProductSuccessAction {
  product: IGetProductDetailsResponse;
}

function getDetailsSuccess(
  draft: Draft<IProductData>,
  action: PayloadAction<IGetDetailsProductSuccessAction>,
) {
  draft.getDetails.config.isLoading = false;
  draft.getDetails.product = {
    barcode: action.payload.product.barcode,
    id: action.payload.product.id,
    images: action.payload.product.images,
    manufacturer: {
      name: action.payload.product.manufacturer.name,
      id: action.payload.product.manufacturer.id,
    },
    sku: action.payload.product.sku || 'Não informado',
    name: action.payload.product.name,
    description: action.payload.product.description || 'Não informado',
    otherInfo: action.payload.product.otherInfo || 'Não informado',
  };
}

export default getDetailsSuccess;
