import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient/index';
import IGetProductDetailsResponse from 'services/httpClient/responses/product/IGetProductDetailsResponse';

import { productActions } from '../index';
import { IGetProductDetailsRequestAction } from '../reducers/getDetails/request';

function* getDetails(action: PayloadAction<IGetProductDetailsRequestAction>) {
  try {
    const response: AxiosResponse<IGetProductDetailsResponse> = yield call(
      httpClient.get,
      `webs/products/${action.payload.data.id}`,
    );
    if (response.status === EHttpStatusCodes.NO_CONTENT) {
      action.payload.functions.goBack();
      return;
    }
    yield put(
      productActions.getDetailsSuccess({
        product: response.data,
      }),
    );
  } catch (err) {
    action.payload.functions.error(err);
    yield put(productActions.getDetailsFailure());
  }
}

export default getDetails;
