import styled from 'styled-components';

import animations from 'styles/animations';

interface IItemsBodyLoadingProps {
  addColorRow?: boolean;
}

export const ItemsBodyLoading = styled.div<IItemsBodyLoadingProps>`
  align-items: center;
  animation: ${animations.shimmer} 2s infinite linear;
  background: linear-gradient(to right, #f9f9f9 4%, #dedede 25%, #f9f9f9 36%);
  background-size: 1000px 100%;
  border-radius: 1rem;
  column-gap: 0.5rem;
  display: grid;
  min-height: 17rem;
  width: 100%;
`;
