import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { useTour } from '@reactour/tour';
import { FormikProps } from 'formik';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import authSelectors from 'store/slices/auth/selectors';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';
import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import helpers from 'helpers';

import Containers from 'styles/containers';

import ProductManufacturerFilter from './Filter/Manufacturer';
import ProductSearchFilter, {
  IProductSearchFilterFormData,
} from './Filter/Search';
import ProductItem from './Item';
import {
  Container,
  Content,
  PageTitle,
  Products,
  Small,
  Subheader,
} from './styles';

const ProductList = (): JSX.Element => {
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();
  const { setIsOpen } = useTour();

  const showTour = useReduxSelector(authSelectors.showTour);
  const isLoading = useReduxSelector(productSelectors.getAllIsLoading);
  const products = useReduxSelector(productSelectors.getAllList);
  const totalProducts = useReduxSelector(productSelectors.getAllTotalProducts);
  const pagination = useReduxSelector(productSelectors.getAllPagination);
  const errorMessage = useReduxSelector(productSelectors.getAllErrorMessage);
  const emptyMessage = useReduxSelector(productSelectors.getAllEmptyMessage);
  const manufacturerCheckedIds = useReduxSelector(
    manufacturerSelectors.manufacturerCheckedIds,
  );

  const productSearchFilterRef =
    useRef<FormikProps<IProductSearchFilterFormData>>(null);

  const loadProducts = useCallback(() => {
    reduxDispatch(
      productActions.getAllRequest({
        data: {
          page: 1,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    page => {
      reduxDispatch(
        productActions.getAllRequest({
          data: {
            page,
            manufacturerIds: manufacturerCheckedIds,
            nameOrBarcodeOrSku:
              productSearchFilterRef.current?.values.nameOrBarcodeOrSku,
          },
          functions: {
            error(err) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [manufacturerCheckedIds, reduxDispatch],
  );

  const handleFilter = useCallback(
    async (data: IProductSearchFilterFormData) => {
      reduxDispatch(
        productActions.getAllRequest({
          data: {
            page: 1,
            manufacturerIds: manufacturerCheckedIds,
            nameOrBarcodeOrSku: data.nameOrBarcodeOrSku,
          },
          functions: {
            error(err: any) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [manufacturerCheckedIds, reduxDispatch],
  );

  const handleClearFilter = useCallback(() => {
    productSearchFilterRef.current?.resetForm();
    reduxDispatch(
      productActions.getAllRequest({
        data: {
          page: 1,
          manufacturerIds: manufacturerCheckedIds,
        },
        functions: {
          error(err: any) {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [manufacturerCheckedIds, reduxDispatch]);

  const goToDetails = useCallback(
    (productId: number) => {
      navigate(pages.product.details(productId));
    },
    [navigate],
  );

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    const body = document.getElementById('mainBody');
    if (body && showTour) {
      setIsOpen(true);
      body.classList.add('overflowHidden');
    }
  }, [setIsOpen, showTour]);

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, [setIsOpen]);

  const quantityProducts = useMemo(() => {
    if (totalProducts <= 1) {
      return `${totalProducts} produto cadastrado`;
    }
    return `${totalProducts} produtos cadastrados`;
  }, [totalProducts]);

  return (
    <Container>
      <Containers.Global>
        <Subheader>
          <PageTitle>
            Listagem de produtos
            <Small>{quantityProducts}</Small>
          </PageTitle>

          <ProductSearchFilter
            isLoading={isLoading}
            onClear={handleClearFilter}
            onSubmit={handleFilter}
            ref={productSearchFilterRef}
          />
        </Subheader>

        <Content>
          <ProductManufacturerFilter
            productIsLoading={isLoading}
            searchBarRef={productSearchFilterRef}
          />
          <Products>
            <ComponentLoadingList show={isLoading} />

            <ComponentIsVisible when={!isLoading}>
              <ComponentIsVisible when={!!products.length}>
                {products.map(product => (
                  <ProductItem
                    dataTut="product-item"
                    key={product.id}
                    openDetails={() => goToDetails(product.id)}
                    product={product}
                  />
                ))}
              </ComponentIsVisible>

              <ComponentIsVisible when={!products?.length && !errorMessage}>
                <ComponentEmpty message={emptyMessage} show={!!emptyMessage} />
              </ComponentIsVisible>

              <ComponentIsVisible when={!!errorMessage}>
                <ComponentError message={errorMessage} onClick={loadProducts} />
              </ComponentIsVisible>

              <ComponentPaginate
                currentPage={pagination.page}
                onPage={handlePagination}
                show={!!products?.length}
                totalPages={pagination.totalPages}
              />
            </ComponentIsVisible>
          </Products>
        </Content>
      </Containers.Global>
    </Container>
  );
};

export default ProductList;
