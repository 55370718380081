import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

export interface ISetTourAction {
  value: boolean;
}

function setTour(
  draft: Draft<IAuthData>,
  action: PayloadAction<ISetTourAction>,
) {
  draft.showTour = action.payload.value;
}

export default setTour;
