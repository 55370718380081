import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

export interface IGetProductToPdfRequestAction {
  data: {
    id: number;
  };
  functions: {
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function getProductToPdfRequest(
  draft: Draft<IProductData>,
  _: PayloadAction<IGetProductToPdfRequestAction>,
) {
  draft.getProductToPdf.config.isLoading = true;
}

export default getProductToPdfRequest;
