import changePasswordFailure from './changePassword/failure';
import changePasswordRequest from './changePassword/request';
import changePasswordSuccess from './changePassword/success';
import logoutRequest from './logout/request';
import setProfile from './set/profile';
import setTour from './set/tour';
import signInFailure from './signIn/failure';
import signInRequest from './signIn/request';
import signInSuccess from './signIn/success';
import validateAccountFailure from './validateAccount/failure';
import validateAccountRequest from './validateAccount/request';
import validateAccountSuccess from './validateAccount/success';

const reducers = {
  changePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  logoutRequest,
  setProfile,
  setTour,
  signInFailure,
  signInRequest,
  signInSuccess,
  validateAccountFailure,
  validateAccountRequest,
  validateAccountSuccess,
};

export default reducers;
