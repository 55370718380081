import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.button`
  background-color: ${colors.white900};
  border: 1px solid ${colors.gray100};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  width: 100%;
  @media screen and (min-width: 768px) {
    padding: 1.5rem;
  }
`;

export const Thumbnail = styled.div`
  align-items: center;
  display: flex;
  height: 7.2rem;
  justify-content: center;
  margin-bottom: 0.875rem;
  position: relative;
  width: 100%;
  @media screen and (min-width: 768px) {
    height: 9.5rem;
  }
`;

export const Image = styled.img`
  height: auto;
  max-height: 7.2rem;
  max-width: 100%;
  width: auto;
  @media screen and (min-width: 768px) {
    max-height: 9.5rem;
  }
`;

export const Name = styled.h4`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${colors.gray900};
  display: -webkit-box;
  font-size: 0.925rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;
  @media screen and (min-width: 768px) {
    -webkit-line-clamp: 3;
  }
`;

export const Manufacturer = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${colors.gray600};
  display: -webkit-box;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;
`;

export const Ean = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-items: center;
  color: ${colors.cyan900};
  display: -webkit-box;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0.115rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;

  & svg {
    margin-right: 0.375rem;
    vertical-align: middle;
  }
`;

export const Sku = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${colors.orange700};
  display: -webkit-box;
  font-size: 0.875rem;
  font-weight: 400;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;

  & svg {
    margin-right: 0.375rem;
    vertical-align: middle;
  }
`;
