import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

export interface IGetProductsToPdfRequestAction {
  data: {
    ids: number[];
  };
  functions: {
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function getProductsToPdfRequest(
  draft: Draft<IManufacturerData>,
  _: PayloadAction<IGetProductsToPdfRequestAction>,
) {
  draft.getProductsToPdf.config.isLoading = true;
}

export default getProductsToPdfRequest;
