import { all } from 'redux-saga/effects';

import authSagas from './slices/auth/sagas';
import manufacturerSagas from './slices/manufacturer/sagas';
import productSagas from './slices/product/sagas';

function* rootSagas() {
  yield all([authSagas, productSagas, manufacturerSagas]);
}

export default rootSagas;
