import { createGlobalStyle } from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { shade } from 'polished';

import colors from './colors';

export default createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    outline: none;
    -webkit-font-smoothing: antialiased !important;
  }
  html {
    @media screen and (max-width: 1600px) {
      font-size: 93.75%;
    }
    @media screen and (max-width: 1440px) {
        font-size: 90%;
    }
    @media screen and (max-width: 720px) {
        font-size: 87.5%;
    }

  @media (min-width: 900px) {
    ::-webkit-scrollbar {
      background: ${shade(0.1, colors.orange900)};
      height: 0.5rem;
      width: 0.75rem;
    }

    ::-webkit-scrollbar-button {
      height: 0;
      width: 0;
    }

    ::-webkit-scrollbar-track {
      background: ${colors.background};
    }

    ::-webkit-scrollbar-thumb {
      background: ${shade(0.1, colors.orange900)};
      height: 0.5rem;
      width: 0.75rem;
    }
  }
  body {
    overflow-y: scroll;
  }
  body.overflowHidden {
    overflow: hidden;
  }
  .virtualizedListScrollBar {
    margin-top: .25rem;
      ::-webkit-scrollbar {
        background: ${colors.gray900};
        height: 0.5rem;
        width: 0.4rem;
      }
      ::-webkit-scrollbar-button {
        height: 0;
        width: 0;
      }
      ::-webkit-scrollbar-track {
        background: ${colors.background};
        height: 0.5rem;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.gray900};
        height: 0.5rem;
        width: 0.4rem;
        border-radius: 0 0 0.25rem 0.25rem;
      }
    }
}

html, body, #root {
    background-color: ${colors.background};
    min-height: 100vh;
  }
  body {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-rendering: optimizeLegibility;
    line-height: 1;
  }
  body, input, button, textarea {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.gray500};
  }
  input, textarea {
    color: ${colors.gray500};
    &::placeholder {
      color: ${colors.gray200};
      font-size: 0.875rem;
      font-weight: 300;
    }
  }
  button {
    cursor: pointer;
    transition: all .3s ease;
  }
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease-in-out;
  }
  [disabled] {
    opacity: .6;
    cursor: not-allowed;
  }
  .ulPaginate {
    display: flex;
    list-style: none;
    margin: .5rem 0 0;
    grid-column: 1 / 2;
    padding: 0;
    align-items: center;
    column-gap: .25rem;
    grid-template-columns: repeat(2, 1fr);
    @media all and (min-width: 768px) {
      grid-column: 1 / 3;
    }
    @media all and (min-width: 1240px) {
      grid-column: 1 / 4;
    }
    @media all and (min-width: 1600px) {
      grid-column: 1 / 5;
    }
  }
  .ulPaginate li {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white100};
    border: 1px solid ${colors.gray100};
    border-radius: .375rem;
    color: ${colors.orange900};
    cursor: pointer;
    font-size: .875rem;
    font-weight: 600;
    height: 1.75rem;
    transition: all .3s ease-in-out;
    width: 1.75rem;
    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    &.previous {
      font-size: 1.75rem;
      border: 1px solid ${colors.gray100};
      background-color: ${colors.white100};
      color: ${colors.gray200};
      border-radius: .375rem;
      font-weight: 400;
      width: 1.75rem;
      padding-bottom: 0.115rem;
      & a {
        width: 100%;
      }
    }
    &.next {
      font-size: 1.75rem;
      border: 1px solid ${colors.gray100};
      background-color: ${colors.white100};
      color: ${colors.gray200};
      border-radius: .375rem;
      font-weight: 400;
      width: 1.75rem;
      padding-bottom: 0.115rem;
    }
    :hover {
      opacity: .8
    }
    &.active {
      background: ${colors.orange900};
      color: ${colors.white900};
      border: 0;
      border-radius: .375rem;
      font-weight: 600;
    }
  }
`;
