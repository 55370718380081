import { useCallback } from 'react';

import { BrowserRouter } from 'react-router-dom';

import { TourProvider } from '@reactour/tour';

import AppProvider from 'hooks';
import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentFooter from 'components/Footer';
import ComponentHeader from 'components/Header';
import ComponentIsVisible from 'components/utils/IsVisible';

import Routes from 'routes';
import ErrorBoundary from 'routes/ErrorBoundary';

import Redirect from 'services/httpClient/Redirect';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import GlobalStyle from 'styles/global';

const App: React.FC = () => {
  const reduxDispatch = useReduxDispatch();
  const isSigned = useReduxSelector(authSelectors.token);
  const showTour = useReduxSelector(authSelectors.showTour);

  const steps = [
    {
      selector: '[data-tut="search-bar"]',
      content: 'Para pesquisar entre os produtos você pode usar este campo.',
    },
    {
      selector: '[data-tut="filter-item"]',
      content:
        'É possível também filtrar os produtos por fabricantes. Para filtrar marque o fabricante.',
    },
    {
      selector: '[data-tut="product-item"]',
      content:
        'Veja as principais informações dos produtos na listagem e clique no mesmo para mais informações',
    },
  ];

  const body = document.getElementById('mainBody');

  const handleCloseTour = useCallback(
    click => {
      click.setIsOpen(false);
      click.setCurrentStep(0);
      reduxDispatch(
        authActions.setTour({
          value: false,
        }),
      );
      body?.classList.remove('overflowHidden');
    },
    [body, reduxDispatch],
  );

  return (
    <AppProvider>
      <TourProvider
        defaultOpen={showTour}
        onClickClose={click => handleCloseTour(click)}
        onClickMask={click => handleCloseTour(click)}
        steps={steps}
        styles={{
          popover: base => ({
            ...base,
            borderRadius: '1rem',
          }),
          maskArea: base => ({ ...base, rx: '1rem' }),
          badge: base => ({ ...base, backgroundColor: colors.cyan900 }),
          controls: base => ({ ...base, marginTop: 32, color: colors.gray300 }),
          close: base => ({ ...base, right: 16, left: 'auto', top: 8 }),
          dot: base => ({
            ...base,
            backgroundColor: colors.gray300,
          }),
        }}
      >
        <ErrorBoundary>
          <BrowserRouter>
            <Containers.Main>
              <ComponentIsVisible when={!!isSigned}>
                <ComponentHeader />
              </ComponentIsVisible>

              <Routes />
              <ComponentIsVisible when={!!isSigned}>
                <ComponentFooter />
              </ComponentIsVisible>
            </Containers.Main>
          </BrowserRouter>
        </ErrorBoundary>
      </TourProvider>

      <Redirect />
      <GlobalStyle />
    </AppProvider>
  );
};

export default App;
