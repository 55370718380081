import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IGetAllManufacturerListResponse } from 'services/httpClient/responses/manufacturer/IGetAllManufacturerResponse';

import { IManufacturerData } from '../../index';

export interface IGetAllManufacturerSuccessWithDataAction {
  list: IGetAllManufacturerListResponse[];
}

function getAllSuccessWithData(
  draft: Draft<IManufacturerData>,
  action: PayloadAction<IGetAllManufacturerSuccessWithDataAction>,
) {
  draft.getAll.config.errorMessage = '';
  draft.getAll.config.emptyMessage = '';
  draft.getAll.config.isLoading = false;
  draft.getAll.list = action.payload.list.map(manufacturer => ({
    ...manufacturer,
    checked: false,
  }));
}

export default getAllSuccessWithData;
