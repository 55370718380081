import { CSSProperties } from 'react';

import IManufacturer from 'models/Manufacturer';

import {
  Container,
  ManufacturerCheckbox,
  ManufacturerCheckboxInput,
  ManufacturerCheckboxMark,
  Name,
} from './styles';

interface IManufacturerItemProps {
  index: number;
  isLoading: boolean;
  manufacturers: IManufacturer[];
  onToggleCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  paddingRight?: string;
  style?: CSSProperties;
}

const ManufacturerItem = ({
  index,
  isLoading,
  manufacturers,
  onToggleCheckbox,
  paddingRight,
  style,
}: IManufacturerItemProps): JSX.Element => {
  return (
    <Container data-tut="filter-item" paddingRight={paddingRight} style={style}>
      <Name>{manufacturers[index].name}</Name>
      <ManufacturerCheckbox>
        <ManufacturerCheckboxInput
          checked={manufacturers[index].checked}
          className="checkboxInput"
          disabled={isLoading}
          onChange={onToggleCheckbox}
          type="checkbox"
        />
        <ManufacturerCheckboxMark className="checkmark" />
      </ManufacturerCheckbox>
    </Container>
  );
};

export default ManufacturerItem;
