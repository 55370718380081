import { all, takeLatest } from 'redux-saga/effects';

import { manufacturerActions } from '../index';
import getAll from './getAll';
import getProductsToPdf from './getProductsToPdf';

const manufacturerSagas = all([
  takeLatest(manufacturerActions.getAllRequest, getAll),
  takeLatest(manufacturerActions.getProductsToPdfRequest, getProductsToPdf),
]);

export default manufacturerSagas;
