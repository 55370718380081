import { createSlice } from '@reduxjs/toolkit';
import IManufacturer from 'models/Manufacturer';
import IManufacturerProducts from 'models/ManufacturerProducts';

import reducers from './reducers';

export interface IManufacturerData {
  allChecked: boolean;
  checkedIds: number[];
  getAll: {
    config: {
      emptyMessage: string;
      errorMessage: string;
      isLoading: boolean;
    };
    list: IManufacturer[];
  };
  getProductsToPdf: {
    config: {
      emptyMessage: string;
      errorMessage: string;
      isLoading: boolean;
    };
    list: IManufacturerProducts[];
  };
}

const initialState: IManufacturerData = {
  allChecked: false,
  checkedIds: [],
  getAll: {
    config: {
      emptyMessage: 'Nenhum fabricante encontrado',
      errorMessage: '',
      isLoading: false,
    },
    list: [],
  },
  getProductsToPdf: {
    config: {
      emptyMessage: 'Nenhum fabricante e/ou produto encontrado',
      errorMessage: '',
      isLoading: false,
    },
    list: [],
  },
};

const manufacturerSlice = createSlice({
  name: '@manufacturer',
  initialState,
  reducers,
});

export const manufacturerActions = manufacturerSlice.actions;
export const manufacturerReducers = manufacturerSlice.reducer;
