import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ScaleLoader } from 'react-spinners';
import { AutoSizer, List } from 'react-virtualized';

import { FormikProps } from 'formik';
import IManufacturer from 'models/Manufacturer';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentIsVisible from 'components/utils/IsVisible';

import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';
import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import helpers from 'helpers';

import colors from 'styles/colors';

import { IProductSearchFilterFormData } from '../Search';
import ManufacturerItem from './Item';
import {
  Container,
  EmptyManufacturers,
  GeneratePdfButton,
  Manufacturers,
  Title,
} from './styles';

interface IProductManufacturerFilterProps {
  productIsLoading: boolean;
  searchBarRef: React.RefObject<FormikProps<IProductSearchFilterFormData>>;
}

const ProductManufacturerFilter = ({
  productIsLoading,
  searchBarRef,
}: IProductManufacturerFilterProps): JSX.Element => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const manufacturers = useReduxSelector(manufacturerSelectors.getAllList);
  const emptyManufacturers = useReduxSelector(
    manufacturerSelectors.getAllEmptyMessage,
  );
  const totalProducts = useReduxSelector(productSelectors.getAllTotalProducts);
  const isLoading = useReduxSelector(manufacturerSelectors.getAllIsLoading);
  const productsToPdfIsLoading = useReduxSelector(
    manufacturerSelectors.getProductsToPdfIsLoading,
  );
  const manufacturerCheckedIds = useReduxSelector(
    manufacturerSelectors.manufacturerCheckedIds,
  );

  const [selectAllManufacturerOptions, setSelectAllManufacturerOptions] =
    useState<IManufacturer[]>([
      {
        checked: false,
        id: 0,
        name: 'Selecionar todos',
      },
    ]);

  const listRef = useRef<List>(null);

  const loadManufacturers = useCallback(() => {
    reduxDispatch(
      manufacturerActions.getAllRequest({
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handleToggleChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, manufacturerId: number) => {
      if (manufacturerId === 0) {
        reduxDispatch(
          manufacturerActions.setToggleCheckAll({
            data: {
              value: event.target.checked,
            },
          }),
        );
        setSelectAllManufacturerOptions(manufacturerOptions => {
          const newManufacturerOptions = manufacturerOptions.map(
            manufacturerOption => ({
              ...manufacturerOption,
              checked: !manufacturerOption.checked,
            }),
          );

          return newManufacturerOptions;
        });
        return;
      }
      reduxDispatch(
        manufacturerActions.setToggleCheck({
          data: {
            id: manufacturerId,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const hasManufacturerSelected = useMemo(() => {
    const manufacturersFiltered = manufacturers.filter(
      manufacturer => manufacturer.checked === true,
    );

    return manufacturersFiltered.length >= 1;
  }, [manufacturers]);

  const MemoizedComponentEmpty = useCallback(
    () => (
      <EmptyManufacturers show={!!emptyManufacturers}>
        Nenhum fabricante adicionado
      </EmptyManufacturers>
    ),
    [emptyManufacturers],
  );

  const MemoizedManufacturerItem = useCallback(
    ({ index, key, style }) => (
      <ManufacturerItem
        index={index}
        isLoading={isLoading || productIsLoading}
        key={key}
        manufacturers={manufacturers}
        onToggleCheckbox={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleToggleChecked(event, manufacturers[index].id)
        }
        style={style}
      />
    ),
    [handleToggleChecked, isLoading, manufacturers, productIsLoading],
  );

  const handleGeneratePdf = useCallback(() => {
    const manufacturersFilteredIds = manufacturers
      .filter(manufacturer => manufacturer.checked === true)
      .map(manufacturerFiltered => manufacturerFiltered.id);

    reduxDispatch(
      manufacturerActions.getProductsToPdfRequest({
        data: {
          ids: manufacturersFilteredIds,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
          success: (message: string) => {
            toast.show({
              title: message,
              type: 'success',
            });
          },
        },
      }),
    );
  }, [manufacturers, reduxDispatch, toast]);

  const virtualizedListHeight = useMemo(() => {
    const windowWidth = window.innerWidth;

    const listHeight = windowWidth >= 1024 ? 35 : 30;

    return listHeight;
  }, []);

  useEffect(() => {
    loadManufacturers();
  }, [loadManufacturers]);

  useEffect(() => {
    reduxDispatch(
      productActions.getAllRequest({
        data: {
          manufacturerIds: manufacturerCheckedIds,
          page: 1,
          nameOrBarcodeOrSku: searchBarRef.current?.values.nameOrBarcodeOrSku,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [manufacturerCheckedIds, reduxDispatch, searchBarRef]);

  return (
    <Container>
      <Title>Filtrar por fabricante</Title>

      <Manufacturers>
        <ComponentIsVisible when={!!manufacturers.length}>
          <ManufacturerItem
            index={0}
            isLoading={isLoading || productIsLoading}
            manufacturers={selectAllManufacturerOptions}
            onToggleCheckbox={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleToggleChecked(event, 0)
            }
            paddingRight=".875rem"
          />

          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                className="virtualizedListScrollBar"
                height={virtualizedListHeight * 12}
                noRowsRenderer={MemoizedComponentEmpty}
                overscanRowCount={8}
                ref={listRef}
                rowCount={manufacturers.length}
                rowHeight={virtualizedListHeight}
                rowRenderer={MemoizedManufacturerItem}
                width={width}
              />
            )}
          </AutoSizer>
        </ComponentIsVisible>
      </Manufacturers>
      {hasManufacturerSelected && totalProducts <= 1000 ? (
        <GeneratePdfButton
          disabled={productsToPdfIsLoading}
          onClick={handleGeneratePdf}
        >
          <ComponentIsVisible when={!productsToPdfIsLoading}>
            Gerar Pdf
          </ComponentIsVisible>
          <ComponentIsVisible when={productsToPdfIsLoading}>
            <ScaleLoader color={colors.orange900} height={15} width={2} />
          </ComponentIsVisible>
        </GeneratePdfButton>
      ) : (
        <p
          style={{
            marginTop: '1rem',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Permitido apenas 1000 produtos para gerar o PDF
        </p>
      )}
    </Container>
  );
};

export default ProductManufacturerFilter;
