export default {
  auth: {
    signIn: '/',
    forgotPassword: '/recuperar-senha',
    resetPassword: '/resetar-senha',
    validateAccount: (token: string) => `/validar/${token}`,
  },
  product: {
    list: '/produtos',
    details: (id: number) => `/produtos/${id}/detalhes`,
  },
  error: '/erro-500',
};
