import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

export interface IGetAllManufacturerSuccessWithoutDataAction {
  message: string;
}
function getAllSuccessWithoutData(
  draft: Draft<IManufacturerData>,
  action: PayloadAction<IGetAllManufacturerSuccessWithoutDataAction>,
) {
  draft.getAll.config.emptyMessage = action.payload.message;
  draft.getAll.config.isLoading = false;
}

export default getAllSuccessWithoutData;
