import { createSlice } from '@reduxjs/toolkit';
import EPdfType from 'enums/pdfType';

import reducers from './reducers';

export interface IAuthDataAuthorization {
  token: string | null;
}

export interface IAuthDataProfile {
  company: {
    id: number | null;
    logo: string | null;
    name: string | null;
    pdfType: EPdfType | null;
  };
  id: number | null;
  name: string | null;
}

export interface IAuthData {
  authorization: IAuthDataAuthorization;
  changePassword: {
    config: {
      isLoading: boolean;
    };
  };
  config: {
    isLoading: boolean;
  };
  profile: IAuthDataProfile;
  showTour: boolean;
  validateAccount: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IAuthData = {
  authorization: { token: null },
  changePassword: {
    config: {
      isLoading: false,
    },
  },
  config: {
    isLoading: false,
  },
  profile: {
    company: {
      id: null,
      logo: null,
      name: null,
      pdfType: null,
    },
    id: null,
    name: null,
  },
  showTour: false,
  validateAccount: {
    config: {
      isLoading: false,
    },
  },
};

const authSlice = createSlice({
  name: '@auth',
  initialState,
  reducers,
});

export const authActions = authSlice.actions;
export const authReducers = authSlice.reducer;
