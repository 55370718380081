import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccessWithData from './getAll/successWithData';
import getAllSuccessWithoutData from './getAll/successWithoutData';
import getDetailsFailure from './getDetails/failure';
import getDetailsRequest from './getDetails/request';
import getDetailsSuccess from './getDetails/success';
import getProductToPdfFailure from './getProductToPdf/failure';
import getProductToPdfRequest from './getProductToPdf/request';
import getProductToPdfSuccess from './getProductToPdf/success';
import setClearDetails from './set/clearDetails';

const reducers = {
  getAllFailure,
  getAllRequest,
  getAllSuccessWithData,
  getAllSuccessWithoutData,
  getDetailsFailure,
  getDetailsRequest,
  getDetailsSuccess,
  setClearDetails,
  getProductToPdfFailure,
  getProductToPdfRequest,
  getProductToPdfSuccess,
};

export default reducers;
