import { PuffLoader } from 'react-spinners';

import ComponentButtonBase from 'components/button/Base';
import ComponentIsVisible from 'components/utils/IsVisible';

import authLogo from 'assets/logo/auth-logo.png';

import colors from 'styles/colors';

import {
  Actions,
  Container,
  Content,
  Loader,
  Logo,
  Message,
  Title,
} from './styles';

const ValidateAccount = (): JSX.Element => {
  const status = null;
  return (
    <Container>
      <Content>
        <Logo alt="Logomarca do site" src={authLogo} />
        <ComponentIsVisible when={status !== null && status}>
          <Title>Sua conta foi validada!</Title>
          <Message>
            Você já pode acessar a sua conta, ver toda a lista de produtos e
            criar novas cotações para seus clientes!
          </Message>
          <Message>
            Esperamos que goste de sua primeira experiência em nosso sistema.
          </Message>
          <Message>Equipe Rac Systems</Message>

          <Actions>
            <ComponentButtonBase>Ir para o login</ComponentButtonBase>
          </Actions>
        </ComponentIsVisible>

        <ComponentIsVisible when={status === null}>
          <Title>Validando conta</Title>
          <Loader>
            <PuffLoader color={colors.gray900} size={40} />
          </Loader>
        </ComponentIsVisible>

        <ComponentIsVisible when={status !== null && !status}>
          <Title>Esta conta não pode ser verificada.</Title>
          <Message>
            Esta conta não contém código de ativação. Ela pode já ter sido
            ativada ou está em processo de criação no momento. Tente logar
            normalmente ou entre em contato conosco.
          </Message>
        </ComponentIsVisible>
      </Content>
    </Container>
  );
};

export default ValidateAccount;
