import { PayloadAction } from '@reduxjs/toolkit';
import QueryString from 'qs';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient/index';

import { productActions } from '../index';
import { IGetProductToPdfRequestAction } from '../reducers/getProductToPdf/request';

function* getProductToPdf(
  action: PayloadAction<IGetProductToPdfRequestAction>,
) {
  try {
    const params = {
      id: action.payload.data.id,
    };
    action.payload.functions.success('O PDF está sendo gerado!');
    const { data } = yield call(httpClient.get, 'webs/products/pdf/gerar', {
      timeout: 0,
      responseType: 'blob',
      params,
      paramsSerializer: params => {
        return QueryString.stringify(params);
      },
    });
    yield put(productActions.getProductToPdfSuccess());
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: 'application/pdf',
      }),
    );
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.setAttribute('download', `Meu-Catalogo.pdf`);
    anchorElement.click();
  } catch (err) {
    action.payload.functions.error(err);
    yield put(productActions.getProductToPdfFailure());
  }
}

export default getProductToPdf;
