import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

interface ISetToggleCheckedManufacturerAction {
  data: {
    id: number;
  };
}

function setToggleCheck(
  draft: Draft<IManufacturerData>,
  action: PayloadAction<ISetToggleCheckedManufacturerAction>,
) {
  draft.getAll.list = draft.getAll.list.map(item => {
    if (item.id === action.payload.data.id) {
      return {
        ...item,
        checked: !item.checked,
      };
    }

    return item;
  });
  draft.checkedIds = draft.getAll.list
    .filter(manufacturer => manufacturer.checked === true)
    .map(manufacturerFiltered => manufacturerFiltered.id);
}

export default setToggleCheck;
