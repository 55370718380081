import { useCallback, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ScaleLoader } from 'react-spinners';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBack from 'components/button/Back';
import ComponentIsVisible from 'components/utils/IsVisible';

import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import helpers from 'helpers';

import withoutPicture from 'assets/utils/without-picture.png';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import { PageTitle } from 'styles/general';

import {
  Action,
  Actions,
  Content,
  Grid,
  Image,
  ImageMiniature,
  ImageMiniatureLink,
  ImagesCarousel,
  ImagesSlider,
  Info,
  InfoContent,
  Label,
  Name,
  Thumbnail,
  Value,
} from './styles';

const ProductDetails = (): JSX.Element => {
  const toast = useToast();
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();
  const params = useParams();

  const product = useReduxSelector(productSelectors.getDetailsProduct);
  const isLoading = useReduxSelector(productSelectors.getDetailsIsLoading);
  const isLoadingPdf = useReduxSelector(
    productSelectors.getProductToPdfIsLoading,
  );

  const loadProductDetails = useCallback(() => {
    reduxDispatch(
      productActions.getDetailsRequest({
        data: {
          id: params.id as string,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
          goBack: () => {
            navigate(pages.product.list, { replace: true });
            toast.show({
              title: 'Produto não encontrado',
              type: 'info',
            });
          },
        },
      }),
    );
  }, [navigate, params.id, reduxDispatch, toast]);

  const sliderMiniatureMemoized = useCallback(
    (index: number) => {
      const thumbnail = product?.images[index];

      return (
        <ImageMiniatureLink>
          <ImageMiniature src={thumbnail?.url || withoutPicture} />
        </ImageMiniatureLink>
      );
    },
    [product?.images],
  );

  const settings = {
    customPaging: (index: number) => sliderMiniatureMemoized(index),
    arrows: false,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleGeneratePdf = useCallback(async () => {
    reduxDispatch(
      productActions.getProductToPdfRequest({
        data: {
          id: product?.id as number,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
          success: (message: string) => {
            toast.show({
              title: message,
              type: 'success',
            });
          },
        },
      }),
    );
  }, [product, reduxDispatch, toast]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    reduxDispatch(productActions.setClearDetails());
  }, [reduxDispatch]);

  useEffect(() => {
    loadProductDetails();
  }, [loadProductDetails]);

  return (
    <Containers.Global minHeight={15}>
      <PageTitle hasBackButton>
        <ComponentButtonBack />
        Detalhes do produto
      </PageTitle>
      <Content>
        <ImagesSlider>
          <ComponentIsVisible when={!!product?.images.length}>
            <ImagesCarousel {...settings}>
              {product?.images.map(image => (
                <Thumbnail key={image.id}>
                  <Image src={image.url} />
                </Thumbnail>
              ))}
            </ImagesCarousel>
          </ComponentIsVisible>
          <ComponentIsVisible when={!product?.images.length}>
            <Thumbnail>
              <Image src={withoutPicture} />
            </Thumbnail>
          </ComponentIsVisible>
        </ImagesSlider>

        <Info>
          <InfoContent>
            <Name isLoading={isLoading}>{product?.name}</Name>
            <Grid>
              <Label>Fabricante</Label>
              <Value isLoading={isLoading}>{product?.manufacturer.name}</Value>
            </Grid>
            <Grid>
              <Label>Código de barras</Label>
              <Value isLoading={isLoading}>{product?.barcode} </Value>
            </Grid>
            <Grid>
              <Label>SKU</Label>
              <Value isLoading={isLoading}>{product?.sku} </Value>
            </Grid>
            <Grid>
              <Label>Apresentação</Label>
              <Value isLoading={isLoading}>{product?.description}</Value>
            </Grid>
            <Grid>
              <Label>Outras informações</Label>
              <Value isLoading={isLoading}>{product?.otherInfo}</Value>
            </Grid>
          </InfoContent>

          <Actions>
            <Action onClick={goBack}>Voltar</Action>
            <Action
              color={colors.orange700}
              disabled={isLoadingPdf}
              onClick={handleGeneratePdf}
            >
              <ComponentIsVisible when={!isLoadingPdf}>
                Gerar PDF
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoadingPdf}>
                <ScaleLoader color={colors.orange900} height={15} width={2} />
              </ComponentIsVisible>
            </Action>
          </Actions>
        </Info>
      </Content>
    </Containers.Global>
  );
};

export default ProductDetails;
