import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  display: flex;
  height: 2rem;
  width: 2rem;
  & svg {
    color: ${colors.white900};
  }
`;
