import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

interface IGlobalProps {
  minHeight?: number;
}

const Global = styled.div<IGlobalProps>`
  margin: -12rem auto 0;
  max-width: 70rem;
  min-height: ${props =>
    props.minHeight
      ? `calc(100vh - ${props.minHeight}rem)`
      : 'calc(100vh - 15rem)'};
  position: relative;
  width: 100%;
  @media screen and (min-width: 1024px) {
    margin: -9rem auto 0;
  }
  @media screen and (min-width: 1440px) {
    max-width: 75rem;
  }
  @media screen and (min-width: 1600px) {
    max-width: 80rem;
  }
`;

export default {
  Root,
  Main,
  Global,
};
