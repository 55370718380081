import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media screen and (min-width: 900px) {
  }
`;

export const Subheader = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 1rem;
  @media screen and (min-width: 768px) {
    grid-template-columns: 0.5fr 1fr;
  }
  @media screen and (min-width: 1240px) {
    padding: 0;
  }
`;

export const PageTitle = styled.h2`
  color: ${colors.white900};
  font-size: 1.25rem;
  font-weight: 600;
`;

export const Small = styled.small`
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.25rem;
`;

export const Content = styled.div`
  display: grid;
  grid-gap: 1rem 0;
  grid-template-rows: 19.75rem 1fr;
  @media screen and (min-width: 1024px) {
    grid-template-rows: 1fr;
    grid-template-columns: 14rem 1fr;
    grid-gap: 0 1rem;
    padding: 0;
  }
`;

export const Products = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  height: fit-content;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
  @media all and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media all and (min-width: 1240px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media all and (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
