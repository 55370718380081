import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { FiHelpCircle, FiLock, FiLogOut, FiSettings } from 'react-icons/fi';

import { useTour } from '@reactour/tour';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentChangePassword, {
  IComponentChangePasswordRefProps,
} from 'components/modal/ChangePassword';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import whiteLogoIcon from 'assets/logo/white-icon.png';
import whiteLogo from 'assets/logo/white-logo.png';

import colors from 'styles/colors';

import {
  Content,
  Header,
  Logo,
  Thumbnail,
  UserInfo,
  UserName,
  UserOption,
  UserOptionButton,
  UserOptions,
} from './styles';

const ComponentHeader = (): JSX.Element => {
  const navigate = useNavigate();
  const { setIsOpen } = useTour();
  const reduxDispatch = useReduxDispatch();
  const profile = useReduxSelector(authSelectors.profile);

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const componentChangePasswordRef =
    useRef<IComponentChangePasswordRefProps>(null);

  const openDropdown = () => {
    setIsOpened(currentOpened => !currentOpened);
  };
  const closeDropdown = useCallback(
    event => {
      if (isOpened && !event.target?.closest('.isOpened')) {
        setIsOpened(false);
      }
    },
    [isOpened],
  );

  const handleOpenTour = useCallback(() => {
    setIsOpened(false);
    setIsOpen(true);
    reduxDispatch(
      authActions.setTour({
        value: true,
      }),
    );
  }, [reduxDispatch, setIsOpen]);

  const handleOpenChangePassword = useCallback(() => {
    setIsOpened(false);
    componentChangePasswordRef.current?.open();
  }, []);

  const handleLogout = useCallback(() => {
    reduxDispatch(authActions.logoutRequest());

    navigate(pages.auth.signIn, { replace: true });
  }, [navigate, reduxDispatch]);

  const windowWidth = useMemo(() => {
    const width = window.innerWidth;
    return width;
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, [closeDropdown]);
  return (
    <Header>
      <Content>
        <Thumbnail>
          <Logo src={windowWidth >= 768 ? whiteLogo : whiteLogoIcon} />
        </Thumbnail>

        <UserInfo>
          <UserName>{profile.company.name}</UserName>
          <UserOptionButton onClick={openDropdown}>
            <FiSettings color={colors.orange700} size={18} />
          </UserOptionButton>
          <UserOptions className="isOpened" isOpen={isOpened}>
            <UserOption onClick={handleOpenTour}>
              <FiHelpCircle size={16} />
              Tutorial
            </UserOption>
            <UserOption onClick={handleOpenChangePassword}>
              <FiLock size={16} />
              Alterar senha
            </UserOption>
            <UserOption logout onClick={handleLogout}>
              <FiLogOut size={16} />
              Sair
            </UserOption>
          </UserOptions>
        </UserInfo>
      </Content>

      <ComponentChangePassword ref={componentChangePasswordRef} />
    </Header>
  );
};

export default ComponentHeader;
