import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { authActions } from '../index';
import { IValidateAccountRequestAction } from '../reducers/validateAccount/request';

function* validateAccount(
  action: PayloadAction<IValidateAccountRequestAction>,
) {
  try {
    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.get,
      `/${action.payload.data.token}`,
    );

    yield put(authActions.validateAccountSuccess());
    action.payload.functions.success(message);
    action.payload.functions.navigateTo();
  } catch (err: any) {
    action.payload.functions.error(err);
    yield put(authActions.validateAccountFailure());
  }
}

export default validateAccount;
