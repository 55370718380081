import { PayloadAction } from '@reduxjs/toolkit';
import QueryString from 'qs';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient/index';
import IGetProductsToPdfRequest from 'services/httpClient/request/manufacturer/IGetProductsToPdfRequest';

import { manufacturerActions } from '../index';
import { IGetProductsToPdfRequestAction } from '../reducers/getProductsToPdf/request';

function* getProductsToPdf(
  action: PayloadAction<IGetProductsToPdfRequestAction>,
) {
  try {
    const params: IGetProductsToPdfRequest = {
      ids: action.payload.data.ids,
    };
    action.payload.functions.success('O PDF está sendo gerado!');
    const { data } = yield call(
      httpClient.get,
      'webs/manufacturers/products/pdf',
      {
        timeout: 0,
        responseType: 'blob',
        params,
        paramsSerializer: params => {
          return QueryString.stringify(params);
        },
      },
    );
    yield put(manufacturerActions.getProductsToPdfSuccess());
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: 'application/pdf',
      }),
    );
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.setAttribute('download', `Meu-Catalogo.pdf`);
    anchorElement.click();
  } catch (err) {
    action.payload.functions.error(err);
    yield put(manufacturerActions.getProductsToPdfFailure());
  }
}

export default getProductsToPdf;
