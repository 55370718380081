import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EPdfType from 'enums/pdfType';

import { IAuthData } from '../../index';

export interface ISetProfileAction {
  company: {
    id: number;
    logo: string | null;
    name: string;
    pdfType: EPdfType;
  };
  id: number;
  name: string;
}

function setProfile(
  draft: Draft<IAuthData>,
  action: PayloadAction<ISetProfileAction>,
) {
  draft.profile.company.id = action.payload.company.id;
  draft.profile.company.logo = action.payload.company.logo;
  draft.profile.company.name = action.payload.company.name;
  draft.profile.company.pdfType = action.payload.company.pdfType;
  draft.profile.id = action.payload.id;
  draft.profile.name = action.payload.name;
}

export default setProfile;
