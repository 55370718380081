import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

function logoutRequest(draft: Draft<IAuthData>, _: PayloadAction) {
  draft.authorization.token = null;
  draft.profile.company.id = null;
  draft.profile.company.name = null;
  draft.profile.company.logo = null;
  draft.profile.company.pdfType = null;
  draft.profile.id = null;
  draft.profile.name = null;
  draft.showTour = false;
}

export default logoutRequest;
