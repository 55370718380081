import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

export interface IValidateAccountRequestAction {
  data: {
    token: string;
  };
  functions: {
    error: (err: any) => void;
    navigateTo: () => void;
    success: (message: string) => void;
  };
}

function validateAccountRequest(
  draft: Draft<IAuthData>,
  _: PayloadAction<IValidateAccountRequestAction>,
) {
  draft.validateAccount.config.isLoading = true;
}

export default validateAccountRequest;
