import { forwardRef, useImperativeHandle, useRef } from 'react';

import { FiSearch, FiX } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputSimple from 'components/input/Simple';

import colors from 'styles/colors';
import Forms from 'styles/forms';

export interface IProductSearchFilterFormData {
  nameOrBarcodeOrSku: string;
}

interface IProductSearchFilterProps {
  isLoading: boolean;
  onClear: () => void;
  onSubmit: (data: IProductSearchFilterFormData) => void;
}

const initialValues: IProductSearchFilterFormData = {
  nameOrBarcodeOrSku: '',
};

const ProductSearchFilter: React.ForwardRefRenderFunction<
  FormikProps<IProductSearchFilterFormData>,
  IProductSearchFilterProps
> = ({ isLoading, onClear, onSubmit }, ref) => {
  const productSearchFormikRef = useRef<
    FormikProps<IProductSearchFilterFormData>
  >({} as FormikProps<IProductSearchFilterFormData>);

  useImperativeHandle(ref, () => ({
    ...productSearchFormikRef.current,
  }));

  return (
    <Formik
      initialValues={initialValues}
      innerRef={productSearchFormikRef}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({ handleChange, values }) => (
        <Forms.Content data-tut="search-bar">
          <Forms.Grid gridTemplateColumns="1fr repeat(2, 2.25rem)">
            <ComponentInputSimple
              onChange={handleChange('nameOrBarcodeOrSku')}
              placeholder="Pesquisar por descrição, código de barras ou SKU"
              value={values.nameOrBarcodeOrSku}
            />
            <ComponentButtonBase disabled={isLoading} type="submit">
              <FiSearch size={18} />
            </ComponentButtonBase>
            <ComponentButtonBase
              backgroundColor={colors.gray200}
              disabled={isLoading}
              onClick={onClear}
            >
              <FiX size={18} />
            </ComponentButtonBase>
          </Forms.Grid>
        </Forms.Content>
      )}
    </Formik>
  );
};

export default forwardRef(ProductSearchFilter);
