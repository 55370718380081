import getAllEmptyMessage from './getAllEmptyMessage';
import getAllErrorMessage from './getAllErrorMessage';
import getAllIsLoading from './getAllIsLoading';
import getAllList from './getAllList';
import getAllPagination from './getAllPagination';
import getAllTotalProducts from './getAllTotalProducts';
import getDetailsIsLoading from './getDetailsIsLoading';
import getDetailsProduct from './getDetailsProduct';
import getProductToPdfIsLoading from './getProductToPdfIsLoading';

const productSelectors = {
  getAllEmptyMessage,
  getAllErrorMessage,
  getAllIsLoading,
  getAllList,
  getAllPagination,
  getAllTotalProducts,
  getDetailsIsLoading,
  getDetailsProduct,
  getProductToPdfIsLoading,
};

export default productSelectors;
