import styled, { css } from 'styled-components';

import Slider from 'react-slick';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Content = styled.div`
  background-color: ${colors.white900};
  border-radius: 1rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin: 1.5rem auto 2rem;
  padding: 1.5rem;
  width: 95%;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 3rem;
  }
  @media screen and (min-width: 1240px) {
    width: auto;
  }
`;

export const ImagesSlider = styled.div`
  margin: 0 auto;
  max-width: 80vw;
  overflow: hidden;
  width: 100%;
  @media (min-width: 750px) {
    padding-right: 1rem;
  }
`;

export const ImagesCarousel = styled(Slider)`
  margin: 1rem auto 0;
  width: 100%;
  .slick-dots {
    bottom: 0;
    display: flex !important;
    flex-flow: row wrap;
    padding: 1rem 0;
    position: relative;
  }
  .slick-dots li {
    height: 3rem;
    margin: 0 0.25rem 0.25rem;
    width: 3rem;
    &.slick-active a {
      border: 2px solid ${colors.cyan900};
    }
    @media screen and (min-width: 1600px) {
      height: 3.75rem;
      width: 3.75rem;
    }
  }
  @media (min-width: 750px) {
    width: 90%;
  }
`;

export const Thumbnail = styled.div`
  margin: 1rem 0;
  width: 100%;
`;

export const Image = styled.img`
  margin: 0 auto;
  max-height: 16.5rem;
  max-width: 90%;
  @media screen and (min-width: 1600px) {
    max-height: 20rem;
  }
`;

export const ImageMiniatureLink = styled.a`
  align-items: center;
  background: ${colors.background};
  border: 0.1rem solid ${colors.gray100};
  border-radius: 0.5rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  padding: 0.5rem;
  width: 3rem;
  @media screen and (min-width: 1600px) {
    height: 4rem;
    width: 4rem;
  }
`;

export const ImageMiniature = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InfoContent = styled.div``;

interface INameProps {
  isLoading?: boolean;
}

export const Name = styled.h3<INameProps>`
  color: ${colors.gray900};
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 1.5rem;
      width: 100%;
    `}
`;

export const Grid = styled.div`
  display: grid;
  margin-top: 1rem;
  row-gap: 0.25rem;
`;

export const Label = styled.label`
  color: ${colors.gray400};
  font-size: 0.875rem;
  font-weight: 400;
`;

interface IValueProps {
  isLoading?: boolean;
}

export const Value = styled.p<IValueProps>`
  color: ${colors.gray600};
  font-size: 0.925rem;
  font-weight: 500;
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0;
      height: 1.5rem;
      width: 100%;
    `}
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  margin-top: 2.5rem;
  width: 100%;
`;

interface IActionProps {
  color?: string;
}

export const Action = styled.button<IActionProps>`
  background-color: ${colors.transparent};
  border: 0;
  color: ${props => (props.color ? props.color : colors.gray600)};
  font-size: 0.925rem;
  font-weight: 500;
  margin: 0 auto;
  width: fit-content;

  &:hover {
    opacity: 0.7;
  }
`;
