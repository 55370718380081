import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IGetAllProductListResponse } from 'services/httpClient/responses/product/IGetAllProductResponse';

import { IProductData } from '../../index';

export interface IGetAllProductSuccessWithDataAction {
  list: IGetAllProductListResponse[];
  totalPages: number;
  totalProducts: number;
}

function getAllSuccessWithData(
  draft: Draft<IProductData>,
  action: PayloadAction<IGetAllProductSuccessWithDataAction>,
) {
  draft.getAll.config.errorMessage = '';
  draft.getAll.config.emptyMessage = '';
  draft.getAll.config.isLoading = false;
  draft.getAll.config.pagination.totalPages = action.payload.totalPages;
  draft.getAll.totalProducts = action.payload.totalProducts;
  draft.getAll.list = action.payload.list.map(product => ({
    barcode: product.barcode,
    id: product.id,
    image: product.image,
    manufacturer: {
      name: product.manufacturer.name,
      id: product.manufacturer.id,
    },
    sku: product.sku,
    name: product.name,
  }));
}

export default getAllSuccessWithData;
