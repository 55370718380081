import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

interface ISetToggleCheckAllManufacturerAction {
  data: {
    value: boolean;
  };
}

function setToggleCheckAll(
  draft: Draft<IManufacturerData>,
  action: PayloadAction<ISetToggleCheckAllManufacturerAction>,
) {
  draft.getAll.list = draft.getAll.list.map(item => ({
    ...item,
    checked: action.payload.data.value,
  }));
  draft.checkedIds = draft.getAll.list
    .filter(manufacturer => manufacturer.checked === true)
    .map(manufacturerFiltered => manufacturerFiltered.id);
  draft.allChecked = action.payload.data.value;
}

export default setToggleCheckAll;
