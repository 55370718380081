import { authReducers, IAuthData } from './slices/auth';
import { IManufacturerData, manufacturerReducers } from './slices/manufacturer';
import { IProductData, productReducers } from './slices/product';

export interface IApplicationState {
  auth: IAuthData;
  manufacturer: IManufacturerData;
  product: IProductData;
}

const rootReducers = {
  auth: authReducers,
  manufacturer: manufacturerReducers,
  product: productReducers,
};

export default rootReducers;
