import { FaBarcode } from 'react-icons/fa';
import { FiGlobe } from 'react-icons/fi';

import IProduct from 'models/Product';

import withoutImage from 'assets/utils/without-picture.png';

import {
  Container,
  Ean,
  Image,
  Manufacturer,
  Name,
  Sku,
  Thumbnail,
} from './styles';

interface IProductItemProps {
  dataTut?: string;
  openDetails: () => void;
  product: IProduct;
}

const ProductItem = ({
  dataTut,
  openDetails,
  product,
}: IProductItemProps): JSX.Element => {
  return (
    <Container data-tut={dataTut} onClick={openDetails}>
      <Thumbnail>
        <Image src={product.image || withoutImage} />
      </Thumbnail>

      <Name>{product.name}</Name>
      <Manufacturer>{product.manufacturer.name}</Manufacturer>
      <Ean>
        <FaBarcode size={14} />
        {product.barcode}
      </Ean>
      <Sku>
        <FiGlobe size={14} />
        {product.sku}
      </Sku>
    </Container>
  );
};

export default ProductItem;
