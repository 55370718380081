import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  background-color: ${colors.white900};
  border: 1px solid ${colors.gray100};
  border-radius: 1rem;
  display: grid;
  height: fit-content;
  padding: 1.25rem 0.5rem 1.25rem 1.25rem;
`;

export const Title = styled.h5`
  color: ${colors.gray900};
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const Manufacturers = styled.div`
  display: grid;
  height: 15rem;
  overflow: hidden;
  transition: all 0.6s ease 0s;
  @media screen and (min-width: 1024px) {
    height: 30rem;
  }
`;

interface IEmptyManufacturersProps {
  show?: boolean;
}

export const EmptyManufacturers = styled.span<IEmptyManufacturersProps>`
  color: ${colors.gray300};
  font-size: 0.75rem;
  padding: 2rem 0.75rem 0;
  text-align: center;
`;

export const GeneratePdfButton = styled.button`
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.orange900};
  display: flex;
  font-size: 0.925rem;
  font-weight: 600;
  margin: 1rem auto 0;
  width: fit-content;

  &:hover {
    opacity: 0.75;
  }
`;
