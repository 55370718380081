import { all, takeLatest } from 'redux-saga/effects';

import { productActions } from '../index';
import getAll from './getAll';
import getDetails from './getDetails';
import getProductToPdf from './getProductToPdf';

const productSagas = all([
  takeLatest(productActions.getAllRequest, getAll),
  takeLatest(productActions.getDetailsRequest, getDetails),
  takeLatest(productActions.getProductToPdfRequest, getProductToPdf),
]);

export default productSagas;
