import styled from 'styled-components';

import { Form } from 'formik';

import colors from './colors';

interface ISubtitleProps {
  color?: string;
  marginTop?: string;
}

const Subtitle = styled.strong<ISubtitleProps>`
  color: ${props => (props.color ? props.color : colors.cyan900)};
  display: flex;
  font-size: 0.93rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: ${props => (props.marginTop ? props.marginTop : '1.5')}rem;
`;

const Content = styled(Form)``;

interface IGridProps {
  gridTemplateColumns?: string;
  marginBottom?: string;
}
const Grid = styled.div<IGridProps>`
  align-items: flex-end;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: ${props =>
    props.gridTemplateColumns ? props.gridTemplateColumns : '1fr'};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '1')}rem;
  row-gap: 1rem;
  width: 100%;
`;

const Actions = styled.div`
  column-gap: 0.5rem;
  display: flex;
  margin: 2rem 0 0 auto;
  max-width: 25rem;
  width: 100%;
`;

export default {
  Subtitle,
  Content,
  Grid,
  Actions,
};
