import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPutChangePasswordRequest from 'services/httpClient/request/auth/IPutChangePasswordRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { authActions } from '../index';
import { IChangePasswordRequestAction } from '../reducers/changePassword/request';

function* changePassword(action: PayloadAction<IChangePasswordRequestAction>) {
  try {
    const body: IPutChangePasswordRequest = {
      oldPassword: action.payload.data.oldPassword.trim(),
      newPassword: action.payload.data.newPassword.trim(),
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      `sessions/passwords/users`,
      body,
    );

    yield put(authActions.changePasswordSuccess());

    action.payload.functions.closeForm();
    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(authActions.changePasswordFailure());
  }
}

export default changePassword;
