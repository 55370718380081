import { useMemo } from 'react';

import { getYear } from 'date-fns';

import racLogo from 'assets/utils/logo-rac.png';

import {
  Bold,
  Content,
  Copyright,
  Footer,
  Info,
  Name,
  RacLogo,
  Thumbnail,
} from './styles';

const ComponentFooter = (): JSX.Element => {
  const year = useMemo(() => {
    return getYear(new Date());
  }, []);

  return (
    <Footer>
      <Content>
        <Info>
          <Name>
            <Bold>Meu</Bold> Catálogo
          </Name>
          <Copyright>
            {`${year} Meu Catalogo. Todos os direitos reservados`}
          </Copyright>
        </Info>

        <Thumbnail>
          <RacLogo src={racLogo} />
        </Thumbnail>
      </Content>
    </Footer>
  );
};

export default ComponentFooter;
