import allChecked from './allChecked';
import getAllEmptyMessage from './getAllEmptyMessage';
import getAllErrorMessage from './getAllErrorMessage';
import getAllIsLoading from './getAllIsLoading';
import getAllList from './getAllList';
import getProductsToPdfIsLoading from './getProductsToPdfIsLoading';
import manufacturerCheckedIds from './manufacturerSelectedIds';

const manufacturerSelectors = {
  allChecked,
  getAllEmptyMessage,
  getAllList,
  getAllIsLoading,
  getAllErrorMessage,
  getProductsToPdfIsLoading,
  manufacturerCheckedIds,
};

export default manufacturerSelectors;
