import styled from 'styled-components';

import colors from 'styles/colors';

interface IContainerProps {
  paddingRight?: string;
}

export const Container = styled.div<IContainerProps>`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2.5rem;
  margin-top: 0.5rem;
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight : '.875rem'};
  @media screen and (min-width: 1024px) {
    padding-right: ${props =>
      props.paddingRight ? props.paddingRight : '.5rem'};
  }
`;

export const Name = styled.label`
  color: ${colors.gray900};
  font-size: 0.875rem;
`;

export const ManufacturerCheckbox = styled.label`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 1.25rem;
  justify-content: center;
  margin-left: auto;
  position: relative;
  user-select: none;
  width: 1.25rem;
  &:hover .checkboxInput ~ .checkmark {
    background-color: ${colors.cyan900};
    border-color: ${colors.transparent};
    opacity: 0.7;
  }
  &:hover .checkboxInput:disabled ~ .checkmark {
    background-color: ${colors.white100};
    border-color: ${colors.gray200};
    cursor: not-allowed;
  }
`;

export const ManufacturerCheckboxInput = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 0;

  &:checked ~ .checkmark {
    background-color: ${colors.cyan900};
    border-color: ${colors.transparent};
  }
  &:checked ~ .checkmark:after {
    opacity: 1;
  }
  &:disabled {
    cursor: not-allowed;
    .checkmark:after {
      background-color: ${colors.white100};
      border-color: ${colors.gray200};
    }
  }
`;

export const ManufacturerCheckboxMark = styled.span`
  background-color: ${colors.transparent};
  border: 1px solid ${colors.gray200};
  border-radius: 5px;
  height: 1.375rem;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 1.375rem;
  &:after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: solid ${colors.white900};
    border-width: 0 1px 1px 0;
    content: '';
    height: 0.7rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(45deg) translateX(-60%);
    width: 0.25rem;
  }
`;
