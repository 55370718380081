import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccessWithData from './getAll/successWithData';
import getAllSuccessWithoutData from './getAll/successWithoutData';
import getProductsToPdfFailure from './getProductsToPdf/failure';
import getProductsToPdfRequest from './getProductsToPdf/request';
import getProductsToPdfSuccess from './getProductsToPdf/success';
import setToggleCheck from './set/toggleCheck';
import setToggleCheckAll from './set/toggleCheckAll';

const reducers = {
  getAllFailure,
  getAllRequest,
  getAllSuccessWithData,
  getAllSuccessWithoutData,
  getProductsToPdfFailure,
  getProductsToPdfRequest,
  getProductsToPdfSuccess,
  setToggleCheck,
  setToggleCheckAll,
};

export default reducers;
