import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

export interface IGetProductDetailsRequestAction {
  data: {
    id: string;
  };
  functions: {
    error: (err: any) => void;
    goBack: () => void;
  };
}

function getDetailsRequest(
  draft: Draft<IProductData>,
  _: PayloadAction<IGetProductDetailsRequestAction>,
) {
  draft.getDetails.config.isLoading = true;
}

export default getDetailsRequest;
