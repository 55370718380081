import styled from 'styled-components';

import colors from 'styles/colors';

export const Footer = styled.footer`
  align-items: center;
  background-color: ${colors.white100};
  border-top: 1px solid ${colors.gray100};
  display: flex;
  padding: 0 2rem;
  width: 100%;
  @media screen and (min-width: 1240px) {
    padding: 0;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  margin: 0 auto;
  max-width: 70rem;
  padding: 1rem 0;
  position: relative;
  width: 100%;
  @media screen and (min-width: 1440px) {
    max-width: 75rem;
  }
  @media screen and (min-width: 1600px) {
    max-width: 80rem;
  }
`;

export const Info = styled.div``;

export const Name = styled.p`
  color: ${colors.gray900};
  font-size: 0.925rem;
  margin-bottom: 0.25rem;
`;

export const Bold = styled.b``;

export const Copyright = styled.p`
  font-size: 0.875rem;
  line-height: 0.925rem;
`;

export const Thumbnail = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
  max-width: 4rem;
  position: relative;
`;

export const RacLogo = styled.img`
  height: auto;
  width: 100%;
`;
