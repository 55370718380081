import changePasswordIsLoading from './changePasswordIsLoading';
import profile from './profile';
import showTour from './showTour';
import signInIsLoading from './signInIsLoading';
import token from './token';

const authSelectors = {
  changePasswordIsLoading,
  profile,
  signInIsLoading,
  token,
  showTour,
};

export default authSelectors;
