import { REHYDRATE } from 'redux-persist';
import { all, takeLatest } from 'redux-saga/effects';

import { authActions } from '../index';
import changePassword from './changePassword';
import setToken from './setToken';
import signIn from './signIn';
import validateAccount from './validateAccount';

const authSagas = all([
  takeLatest(REHYDRATE, setToken),
  takeLatest(authActions.changePasswordRequest, changePassword),
  takeLatest(authActions.signInRequest, signIn),
  takeLatest(authActions.validateAccountRequest, validateAccount),
]);

export default authSagas;
