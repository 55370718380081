import styled from 'styled-components';

import { Form } from 'formik';
import { shade } from 'polished';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  background: ${colors.background};
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  width: 100%;
  @media (min-width: 850px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Background = styled.div`
  display: none;
  @media (min-width: 850px) {
    align-items: center;
    background: ${colors.gray900};
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
`;

export const Image = styled.img`
  max-width: 30rem;
  width: 100%;
`;

export const Content = styled.div`
  align-items: flex-start;
  animation: ${animations.rightIn} 1s ease;
  background: ${colors.background};
  justify-content: space-around;
  margin: 0 auto;
  max-width: 18.75rem;
  width: 100%;
  @media (min-width: 850px) {
    max-width: 20rem;
    padding: 0;
    min-height: auto;
  }
`;

export const Logo = styled.img`
  display: flex;
  margin: 2.5rem auto;
  max-width: 11.25rem;
`;

export const Subtitle = styled.p`
  align-self: flex-start;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

export const FormikForm = styled(Form)`
  max-width: 18.75rem;
  width: 100%;
  @media screen and (min-width: 850px) {
    max-width: 20rem;
  }
`;

export const DashboardLink = styled.a`
  align-items: center;
  background: ${colors.transparent};
  border: 0;
  color: ${colors.gray600};
  column-gap: 0.5rem;
  display: flex;
  font-size: 0.75rem;
  justify-content: center;
  margin: 1.5em auto 0;
  text-decoration: none;
  transition: all 0.3s ease;
  width: auto;

  @media (min-width: 850px) {
    &:hover {
      color: ${shade(0.2, colors.gray600)};
    }
  }
`;
