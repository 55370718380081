import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const Header = styled.header`
  background-color: ${colors.gray900};
  height: 18rem;
  margin-bottom: 1rem;
  width: 100%;
`;

export const Content = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  height: 6rem;
  margin: 0 auto;
  max-width: 70rem;
  padding: 0 1rem 0 0.5rem;
  position: relative;
  width: 100%;
  @media screen and (min-width: 1240px) {
    height: 8rem;
    grid-template-columns: 1fr 0.5fr;
  }
  @media screen and (min-width: 1440px) {
    max-width: 75rem;
  }
  @media screen and (min-width: 1600px) {
    max-width: 80rem;
  }
`;

export const Thumbnail = styled.div`
  max-width: 5rem;
  position: relative;
  @media screen and (min-width: 768px) {
    max-width: 10rem;
  }
`;

export const Logo = styled.img`
  height: auto;
  width: 100%;
`;

export const UserInfo = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const UserName = styled.p`
  color: ${colors.orange700};
  font-size: 0.925rem;
  font-weight: 600;
  margin-right: 0.25rem;
  text-align: right;
`;

export const UserOptionButton = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  display: flex;
  font-size: 0;
  justify-content: center;
  @media screen and (min-width: 1024px) {
    &:hover {
      opacity: 0.6;
    }
  }
`;

interface IUserOptionsProps {
  isOpen?: boolean;
}

export const UserOptions = styled.div<IUserOptionsProps>`
  background-color: ${colors.white900};
  border-radius: 1rem;
  box-shadow: 2px 2px 8px ${colors.boxShadow};
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: flex-end;
  max-width: 10rem;
  opacity: 0;
  overflow: hidden;
  padding-top: 1.25rem;
  position: absolute;
  right: 1.5rem;
  text-align: right;
  top: 75%;
  transform: translateY(4rem);
  transition: all 0.35s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 3;
  @media screen and (min-width: 1240px) {
    right: 0;
  }

  &:before {
    background-color: ${colors.cyan900};
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    content: '';
    height: 1rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    `}
`;

interface IUserOptionProps {
  logout?: boolean;
}

export const UserOption = styled.button<IUserOptionProps>`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  color: ${props => (props.logout ? colors.red500 : colors.gray600)};
  column-gap: 0.5rem;
  display: flex;
  font-size: 0.75rem;
  height: 2.5rem;
  padding: ${props => (props.logout ? '0 1.5rem .5rem' : '0 1.5rem')};
  width: 100%;

  &:hover {
    background-color: ${colors.background};
  }
`;
