import { Route, Routes } from 'react-router-dom';

import pages from 'constants/pages';

import SignIn from 'pages/Auth/SignIn';
import ValidateAccount from 'pages/Auth/ValidateAccount';
import ProductDetails from 'pages/Product/Details';
import ProductList from 'pages/Product/List';

import NotFound from './NotFound';
import PrivateRouteWrapper from './PrivateRouteWrapper';

const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<PrivateRouteWrapper />}>
        <Route element={<ProductList />} path={pages.product.list} />
        <Route element={<ProductDetails />} path="produtos/:id/detalhes" />
      </Route>
      <Route element={<SignIn />} path={pages.auth.signIn} />
      <Route element={<ValidateAccount />} path="/validar-conta/:token" />

      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default MainRoutes;
